import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    Trobaràs tot el temari necessari per aprovar les teves oposicions a Guàrdia
                    Urbana de Barcelona en format de Podcast! Però no estem parlant simplement d'una
                    lectura de temari.
                </p>
                <p>
                    En aquests àudios tindràs tots els temes esquematitzats, parlant dels punts clau
                    més importants i separats pels diferents temes que ens demanen les bases de la
                    convocatòria.
                </p>
                <p>
                    <strong>Actualitzats al 2025!</strong>
                </p>
                <p>
                    <strong>En aquest curs tindràs:</strong>
                </p>
                <ul>
                    <li>Accés als 17 podcasts de cada tema de GUB.</li>
                    <li>
                        Quasi 5 hores d'àudios perquè escoltis les vegades que vulguis i on vulguis.
                    </li>
                    <li>Una narració propera i no monòtona.</li>
                    <li>Música de fons perquè l'escolta sigui més amena i motivadora.</li>
                    <li>
                        Un super-resum al final de cada podcast per a una revisió ràpida de
                        conceptes.
                    </li>
                </ul>
                <p>I el millor de tot és que això està pensat perquè aprofitis el teu temps!</p>
                <p>
                    <strong>Els podcasts:</strong>
                </p>
                <ol>
                    <li>Tema 1. La Constitució espanyola de 1978</li>
                    <li>Tema 2. L'Estatut d'Autonomia de Catalunya.</li>
                    <li>Tema 3. El Municipi</li>
                    <li>Tema 4. La Carta Municipal de Barcelona</li>
                    <li>Tema 5. Coneixement de la ciutat de Barcelona</li>
                    <li>Tema 6. Institucions de la Unió Europea</li>
                    <li>Tema 7. El Procediment Administratiu</li>
                    <li>Tema 8. El marc legal de la seguretat pública i els cossos de seguretat</li>
                    <li>Tema 9. Actuacions relacionades amb la seguretat ciutadana</li>
                    <li>
                        Tema 10. L'Ordenança de mesures per fomentar i garantir la convivència
                        ciutadana a l'espai públic de Barcelona
                    </li>
                    <li>
                        Tema 11. L'Ordenança sobre l'ús de les vies i l'espai públic de Barcelona
                    </li>
                    <li>Tema 12. L'Ordenança de circulació de vianants i de vehicles</li>
                    <li>Tema 13. L'Ordenança de protecció, tinença i venda d'animal</li>
                    <li>Tema 14. L'Estatut bàsic de l'empleat públic.</li>
                    <li>Tema 15. Llei Orgànica 10/1995, de 23 de novembre, del Codi Penal</li>
                    <li>Tema 16. Ètica i deontologia professional</li>
                    <li>
                        Tema 17. Pla d'igualtat d'oportunitats entre dones i homes de l'Ajuntament
                        de Barcelona
                    </li>
                </ol>
                <p>
                    <strong>Comencem?</strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Transforma el teu estudi en una experiència eficaç i motivadora amb els nostres podcasts actualitzats al 2025: aprèn quan vulguis, on vulguis i amb el millor temari per aprovar les oposicions a la Guàrdia Urbana de Barcelona!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <>
            <ProductTemplate item={item}>
                <div className="container lg:px-0 py-20">
                    <Title>Podcast per Oposicions a Guàrdia Urbana de Barcelona 2025</Title>
                    <Text className="text-justify">
                        Trobaràs tot el temari necessari per aprovar les teves oposicions a Guàrdia
                        Urbana de Barcelona en format de Podcast! Però no estem parlant simplement
                        d'una lectura de temari.
                        <br />
                        <br />
                        En aquests àudios tindràs tots els temes esquematitzats, parlant dels punts
                        clau més importants i separats pels diferents temes que ens demanen les
                        bases de la convocatòria.
                        <br />
                        <br />
                        Actualitzats al 2025!
                        <br />
                        <br />
                        En aquest curs tindràs:
                        <br />
                        <br />
                        Accés als 17 podcasts de cada tema de GUB.
                        <br />
                        <br />
                        Quasi 5 hores d'àudios perquè escoltis les vegades que vulguis i on vulguis
                        <br />
                        <br />
                        Una narració propera i no monòtona.
                        <br />
                        <br />
                        Música de fons perquè l'escolta sigui més amena i motivadora.
                        <br />
                        <br />
                        Un super-resum al final de cada podcast per a una revisió ràpida de
                        conceptes. I el millor de tot és que això està pensat perquè aprofitis el
                        teu temps!
                        <br />
                        <br />
                        Els podcasts:
                        <br />
                        <br />
                        Tema 1.La Constitució espanyola de 1978
                        <br />
                        <br />
                        Tema 2. l'Estatut d'Autonomia de Catalunya.
                        <br />
                        <br />
                        Tema 3. El Municipi
                        <br />
                        <br />
                        Tema 4. La Carta Municipal de Barcelona
                        <br />
                        <br />
                        Tema 5. Coneixement de la ciutat de Barcelona
                        <br />
                        <br />
                        Tema 6. Institucions de la Unió Europea
                        <br />
                        <br />
                        Tema 7.El Procediment Administratiu
                        <br />
                        <br />
                        Tema 8. El marc legal de la seguretat pública i els cossos de seguretat
                        <br />
                        <br />
                        Tema 9. Actuacions relacionades amb la seguretat ciutadana
                        <br />
                        <br />
                        Tema 10. L'Ordenança de mesures per fomentar i garantir la convivència
                        ciutadana a l'espai públic de Barcelona
                        <br />
                        <br />
                        Tema 11. L'Ordenança sobre l'ús de les vies i l'espai públic de Barcelona
                        <br />
                        <br />
                        Tema 12. L'Ordenança de circulació de vianants i de vehicles
                        <br />
                        <br />
                        Tema 13. L'Ordenança de protecció, tinença i venda d'animal
                        <br />
                        <br />
                        Tema 14. L'Estatut bàsic de l'empleat públic.
                        <br />
                        <br />
                        Tema 15. Llei Orgànica 10/1995, de 23 de novembre, del Codi Penal
                        <br />
                        <br />
                        Tema 16. Ètica i deontologia professional
                        <br />
                        <br />
                        Tema 17. Pla d'igualtat d'oportunitats entre dones i homes de l'Ajuntament
                        de Barcelona
                        <br />
                        <br />
                        Comencem?
                    </Text>
                </div>

                <div className="bg-gray-100 bg-opacity-50">
                    <div className="container lg:px-0 py-20">
                        <Title>¿Cómo accedo?</Title>
                        <Text className="text-justify">
                            Una vez adquirido a través de la sección{" "}
                            <Link to="/tienda/" className="default-link">
                                Tienda
                            </Link>{" "}
                            recibirás un correo con tus credenciales para poder acceder al curso. En
                            el menú superior encontrarás la opción{" "}
                            <a
                                href="https://cursos.iopos.es"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="default-link"
                            >
                                Plataforma de cursos
                            </a>{" "}
                            que te dirigirá hacia la plataforma donde están almacenados todos los
                            cursos y formaciones.
                        </Text>
                    </div>
                </div>
            </ProductTemplate>
        </>
    );
};
export default Product;
*/
